import React, { useCallback, useEffect, useState } from "react";
import { compose } from "redux";
import withAuthorization from "../shared/auth/withAuthorization";
import {
  BREADCRUMB_VIEW_LINKS,
  MIN_HEIGHT,
  PERMISSION_USER_VIEW
} from "../../utils/constants";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./ViewLinkManagementStyles";
import PageHeaderComponent from "../common/pageHeaderComponent/PageHeaderComponent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useIntl } from "react-intl";
import {
  expireViewLinkService,
  getCustomerList,
  getViewLinksByCustomer
} from "../../services/commonServices";
import { useKeycloak } from "@react-keycloak/web";
import { Typography } from "@material-ui/core";

import ViewLinkTableComponent from "./components/ViewLinkTableComponent";
import AlertDialog from "../common/alertContainer/components/AlertDialog";
import { showMessage } from "../shared/notificationSnack/NotificationSnack";

const useStyles = makeStyles(theme => styles(theme, { MIN_HEIGHT }));

const ViewLinkManagementComponent = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { keycloak, initialized } = useKeycloak();

  const [customers, setCustomers] = useState(null);
  const [customer, setCustomer] = useState("");

  const [viewLinkList, setViewLinkList] = useState(null);
  const [viewLink, setViewLink] = useState(null);

  const getCustomers = useCallback(() => {
    if (initialized && keycloak.authenticated) {
      const successCustomerList = response => {
        setCustomers(response.data);

        if (response.data?.length === 1) {
          setCustomer(response.data[0]);
          getViewLinksByCustomer(response.data[0]._id, handleSetViewLinkList);
        }
      };
      getCustomerList(keycloak, successCustomerList);
    }
  }, [initialized, keycloak]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const handleSetViewLinkList = response => {
    setViewLinkList(response.data);
  };

  const expireLinkCallback = () => {
    getViewLinksByCustomer(customer._id, handleSetViewLinkList);
  };

  const expireLinkErrorCallback = () => {
    showMessage(intl.formatMessage({ id: "view_link__revoke_error" }), "error");
  };

  const handleSelectCustomer = e => {
    setCustomer(e.target.value);
    getViewLinksByCustomer(e.target.value._id, handleSetViewLinkList);
  };

  const handleExpireViewLink = () => {
    expireViewLinkService(
      viewLink._id,
      expireLinkCallback,
      expireLinkErrorCallback
    );

    setViewLink(null);
  };

  return (
    <div className={classes.root}>
      <PageHeaderComponent type={BREADCRUMB_VIEW_LINKS} />
      <div className={classes.userContent}>
        <div className={classes.userForm}>
          <FormControl className={classes.customerSelect}>
            <InputLabel id="demo-simple-select-label">
              {intl.formatMessage({ id: "customer_select" })}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={customer}
              onChange={handleSelectCustomer}
              disabled={customers?.length === 1}
            >
              {customers ? (
                customers.map(customer => (
                  <MenuItem value={customer} key={customer._id}>
                    {customer.description}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>{intl.formatMessage({ id: "customer_no_customers" })}</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        <ViewLinkTableComponent
          classes={classes}
          userList={viewLinkList}
          handleExpirateViewLinkConfirmation={setViewLink}
        />
        <AlertDialog
          buttonCloseText={intl.formatMessage({ id: "close" })}
          buttonAcceptText={intl.formatMessage({ id: "accept" })}
          titleDialog={intl.formatMessage({ id: "view_link__expirate" })}
          description={
            <Typography>
              {intl.formatMessage({ id: "view_link__expirate_confirmation" })}
            </Typography>
          }
          onClose={() => setViewLink(null)}
          dialogOnAccept={handleExpireViewLink}
          openDialog={viewLink}
        />
      </div>
    </div>
  );
};

export default compose(withAuthorization(PERMISSION_USER_VIEW))(
  ViewLinkManagementComponent
);
