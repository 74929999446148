import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector } from "react-redux";
import ViewModuleRoundedIcon from "@material-ui/icons/ViewModuleRounded";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CameraIconComponent from "../../../common/customIcons/cameraIconComponent/CameraIconComponent";
import { ROUTE_DEPLOYMENT_MOSAIC } from "../../../../utils/routes";
import { buildUrl } from "../../../../utils/axiosRequests";
import AbstractTable from "../../../shared/abstractTable/AbstractTable";
import { compareArraysObjectByAttribute } from "../../../../utils/utils";
import { GRAY } from "../../../../utils/constants";
import { tenantPrimaryButton } from "../../../../utils/tenantStyles";

const useStyles = makeStyles(theme => ({
  headerButtonContainer: {
    display: "flex",
    justifyContent: "space-around"
  },
  headerButton: {
    backgroundColor: theme.palette.white.main,
    padding: "4px !important",
    color: GRAY,
    width: 35,
    height: 35,
    "&.Mui-disabled": {
      color: theme.palette.disabled.light
    },
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.white.main
    }
  },
  cameraHeaderButton: {
    backgroundColor: theme.palette.white.main,
    padding: "4px !important",
    color: GRAY,
    width: 35,
    height: 35
  },
  cameraHeaderButtonBackground: {
    position: "absolute",
    width: 35,
    height: 35,
    backgroundColor: theme.palette.white.main,
    zIndex: -1
  },
  cameraHeaderButtonBackgroundDisabled: {
    position: "absolute",
    width: 35,
    height: 35,
    backgroundColor: "transparent",
    zIndex: -1
  },
  button: {
    height: 32
  },
  ellipsis: {
    maxWidth: 150,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  checkboxChecked: {
    color: `${theme.palette.primary.main} !important`
  },
  checkboxUnchecked: {
    color: GRAY
  },
  primaryButton: tenantPrimaryButton(theme)
}));

const CameraTableComponent = ({
  filteredCameraList,
  handleGoToMosaic,
  filterCameraMosaic
}) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const { deployment_id } = useParams();

  const [filterButtonDisabled, setFilterButtonDisabled] = useState(null);
  const [savedCameraList, setSavedCameraList] = useState(
    JSON.parse(localStorage.getItem(deployment_id)) || []
  );
  const cameraList =
    useSelector(state => state.commonState.cameraList[deployment_id]) || [];

  useEffect(() => {
    if (savedCameraList.length > 0) {
      filterCameraMosaic(savedCameraList);
    }
  }, [filterCameraMosaic, savedCameraList]);

  const compareLists = useCallback(() => {
    if (savedCameraList.length) {
      if (
        compareArraysObjectByAttribute(
          filteredCameraList,
          savedCameraList,
          "_id"
        ) ||
        !filteredCameraList.length
      ) {
        setFilterButtonDisabled(true);
      } else {
        setFilterButtonDisabled(false);
      }
    } else {
      setFilterButtonDisabled(!filteredCameraList.length);
    }
  }, [filteredCameraList, savedCameraList]);

  useEffect(() => {
    compareLists();
  }, [compareLists, filteredCameraList, savedCameraList]);

  const handleGoToCamera = camera => {
    filterCameraMosaic([camera]);

    const url = buildUrl(ROUTE_DEPLOYMENT_MOSAIC, { deployment_id });
    history.push(url);
  };

  const handleSaveFilter = () => {
    localStorage.setItem(deployment_id, JSON.stringify(filteredCameraList));
    setSavedCameraList(filteredCameraList);
  };

  const isCameraSelected = camera => {
    return !!filteredCameraList.find(element => element._id === camera._id);
  };

  const unselectCamera = camera => {
    const cameraArray = filteredCameraList.filter(
      cam => cam._id !== camera._id
    );
    filterCameraMosaic(cameraArray);
  };

  const selectCamera = camera => {
    const cameraArray = [...filteredCameraList];
    cameraArray.push(camera);
    filterCameraMosaic(cameraArray);
  };

  const handleSelectedCameras = camera => {
    isCameraSelected(camera) ? unselectCamera(camera) : selectCamera(camera);
  };

  const allCamerasSelected = () => {
    return cameraList.length === filteredCameraList.length;
  };

  const handleAllCameras = () => {
    allCamerasSelected()
      ? filterCameraMosaic([])
      : filterCameraMosaic(cameraList);
  };

  const CAMERA_DESCRIPTION = intl.formatMessage({ id: "camera_description" });
  const CAMERA_TYPE = intl.formatMessage({ id: "camera_type" });
  const CAMERA_MODEL = intl.formatMessage({ id: "camera_model" });
  const MOSAIC_BUTTON = intl.formatMessage({ id: "see_mosaic" });
  const FILTER_BUTTON = intl.formatMessage({ id: "save_filter" });

  return (
    <>
      <AbstractTable
        key={cameraList.length}
        columns={[
          {
            title: (
              <Checkbox
                checked={!!allCamerasSelected()}
                onChange={() => handleAllCameras()}
                inputProps={{ "aria-label": "primary checkbox" }}
                color={!!allCamerasSelected() ? "primary" : "secondary"}
              />
            ),
            render: rowData => (
              <Checkbox
                key={rowData._id}
                checked={!!isCameraSelected(rowData)}
                onChange={() => handleSelectedCameras(rowData)}
                inputProps={{ "aria-label": "primary checkbox" }}
                className={
                  !!isCameraSelected(rowData)
                    ? classes.checkboxChecked
                    : classes.checkboxUnchecked
                }
              />
            ),
            sorting: false,
            draggable: false,
            headerStyle: {
              textAlign: "center",
              minWidth: 10,
              padding: "0px !important"
            },
            cellStyle: {
              textAlign: "center",
              minWidth: 10
            }
          },
          {
            title: CAMERA_DESCRIPTION,
            field: "description",
            render: rowData => <Typography>{rowData.description}</Typography>
          },
          {
            title: CAMERA_TYPE,
            field: "type",
            render: rowData => (
              <Typography>
                {rowData.type} {rowData.use}
              </Typography>
            )
          },
          {
            title: CAMERA_MODEL,
            field: "type",
            render: rowData => (
              <Typography>
                {rowData.brand} {rowData.model}
              </Typography>
            )
          },
          {
            title: (
              <div className={classes.headerButtonContainer}>
                <Tooltip title={FILTER_BUTTON}>
                  <span>
                    <IconButton
                      className={classes.headerButton}
                      onClick={() => handleSaveFilter()}
                      disabled={filterButtonDisabled}
                    >
                      <SaveRoundedIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={MOSAIC_BUTTON}>
                  <span>
                    <IconButton
                      className={classes.headerButton}
                      onClick={() => handleGoToMosaic(deployment_id)}
                    >
                      <ViewModuleRoundedIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            ),
            render: rowData => (
              <Button
                onClick={() => {
                  handleGoToCamera(rowData);
                }}
                size="small"
                color="secondary"
                className={classes.button}
              >
                <CameraIconComponent tooltipText="see_camera" />
              </Button>
            ),
            headerStyle: {
              textAlign: "center"
            },
            cellStyle: {
              textAlign: "center"
            },
            sorting: false,
            draggable: false
          }
        ]}
        data={cameraList.length > 0 ? cameraList : []}
        options={{
          showEmptyDataSourceMessage: true,
          doubleHorizontalScroll: false,
          pageSize:
            cameraList.length < 5 && cameraList.length > 0
              ? cameraList.length
              : 5,
          pageSizeOptions: [
            cameraList.length < 5 && cameraList.length > 0
              ? cameraList.length
              : 0,
            5,
            10,
            25,
            50
          ],
          search: false,
          toolbar: false,
          headerStyle: {
            backgroundColor: GRAY,
            color: "white",
            fontWeight: "bold"
          },
          rowStyle: {
            padding: "0px !important"
          }
        }}
        style={{ boxShadow: "4px 4px 20px rgba(38, 37, 37, 0.1)" }}
      />
    </>
  );
};

CameraTableComponent.propTypes = {
  filteredCameraList: PropTypes.array.isRequired
};

export default CameraTableComponent;
