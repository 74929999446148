import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./LoginIconStyles";
import MeetingRoomRoundedIcon from "@material-ui/icons/MeetingRoomRounded";

const useStyles = makeStyles(theme => styles(theme));

const LoginIconComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MeetingRoomRoundedIcon className={classes.img} />
    </div>
  );
};

export default LoginIconComponent;
