import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import format from "date-fns/format";
import PropTypes from "prop-types";
import ButtonGroupMosaicComponent from "../buttonGroupMosaicComponent/ButtonGroupMosaicComponent";
import FilterPanelComponent from "../../../common/FilterPanelComponent";
import { createFullDate } from "../../../../utils/utils";
import { setPlayingActionCreator } from "../../../../actions/commonActions";
import { useDispatch } from "react-redux";
import { ALERT_TIME_RANGE_VALUE_MILLISECONDS } from "../../../../utils/constants";

const ButtonsFiltersContainer = ({
  live,
  handleApplyFilter,
  recordingPolicy,
  today,
  handleSetLive,
  selectedAlert,
  handleMosaic,
  handleSetDisplayVideoPlayers,
  displayedCameraList,
  displayVideoPlayers,
  fullScreen
}) => {
  const dispatch = useDispatch();
  const [videoDateFromState, setVideoDateFromState] = useState(today);
  const [videoDateToState, setVideoDateToState] = useState(today);
  const [videoTimeFromState, setVideoTimeFromState] = useState(today);
  const [videoTimeToState, setVideoTimeToState] = useState(today);
  const [showFilters, setShowFilters] = useState(false);
  const [allowFilter, setAllowFilter] = useState(false);
  const [durationWarning, setDurationWarning] = useState(false);
  const [futureTimeWarning, setFutureTimeWarning] = useState(false);
  const [recordingPolicyWarning, setRecordingPolicyWarning] = useState(false);

  const setPlaying = data => {
    dispatch(setPlayingActionCreator({ playing: data }));
  };

  const handleSetVideoDateFromState = date => {
    !!date && setVideoDateFromState(date);
  };

  const handleSetVideoDateToState = date => {
    !!date && setVideoDateToState(date);
  };

  const handleSetVideoTimeFromState = date => {
    !!date && setVideoTimeFromState(new Date(date.setSeconds(0)));
  };

  const handleSetVideoTimeToState = date => {
    !!date && setVideoTimeToState(new Date(date.setSeconds(0)));
  };

  const handleSetFilters = () => {
    const formattedFilters = {
      dateFrom: format(videoDateFromState, "yyyy-MM-dd"),
      dateTo: format(videoDateToState, "yyyy-MM-dd"),
      timeFrom: format(videoTimeFromState, "t"),
      timeTo: format(videoTimeToState, "t")
    };
    handleApplyFilter(formattedFilters);
  };

  const handleClearFilter = () => {
    setVideoDateFromState(today);
    setVideoDateToState(today);
    setVideoTimeFromState(today);
    setVideoTimeToState(today);
  };

  const toggleLiveMode = value => {
    handleSetDisplayVideoPlayers(value);
    setShowFilters(!value);
    handleSetLive(value);
    setPlaying(value);
  };

  const validateFilter = useCallback(() => {
    if (
      videoDateFromState.toString() !== "Invalid Date" &&
      videoDateToState.toString() !== "Invalid Date" &&
      videoTimeFromState.toString() !== "Invalid Date" &&
      videoTimeToState.toString() !== "Invalid Date"
    ) {
      const from = createFullDate(
        format(videoDateFromState, "yyyy-MM-dd"),
        +videoTimeFromState
      );
      const to = createFullDate(
        format(videoDateToState, "yyyy-MM-dd"),
        +videoTimeToState
      );
      const now = new Date();

      if (
        from < to &&
        to < now &&
        recordingPolicy &&
        from > new Date(format(today, "T") - recordingPolicy.window * 1000)
      ) {
        setAllowFilter(true);
        setDurationWarning(false);
        setFutureTimeWarning(false);
        setRecordingPolicyWarning(false);
      } else {
        setAllowFilter(false);
        if (to > now) {
          setFutureTimeWarning(true);
        } else if (now > to) {
          setDurationWarning(from.toString() !== to.toString());
          setFutureTimeWarning(false);
          if (
            recordingPolicy &&
            from < new Date(format(today, "T") - recordingPolicy.window * 1000)
          ) {
            setDurationWarning(false);
            setRecordingPolicyWarning(true);
          }
        }

        if (format(from, "t") === format(to, "t")) {
          setRecordingPolicyWarning(false);
        }
      }
    }
  }, [
    videoDateFromState,
    videoDateToState,
    videoTimeFromState,
    videoTimeToState,
    recordingPolicy,
    today
  ]);

  useEffect(() => {
    if (!fullScreen && !live) {
      setShowFilters(true);
    }
  }, [fullScreen, live]);

  useEffect(() => {
    validateFilter();
  }, [validateFilter]);

  useEffect(() => {
    if (live) {
      handleClearFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [live]);

  useEffect(() => {
    if (selectedAlert) {
      setShowFilters(true);

      const timestamp = selectedAlert.timestamp * 1000;

      handleSetVideoDateFromState(
        timestamp - ALERT_TIME_RANGE_VALUE_MILLISECONDS
      );
      handleSetVideoDateToState(
        timestamp + ALERT_TIME_RANGE_VALUE_MILLISECONDS
      );
      handleSetVideoTimeFromState(
        timestamp - ALERT_TIME_RANGE_VALUE_MILLISECONDS
      );
      handleSetVideoTimeToState(
        timestamp + ALERT_TIME_RANGE_VALUE_MILLISECONDS
      );
    }
  }, [selectedAlert]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <ButtonGroupMosaicComponent
          handleMosaic={handleMosaic}
          live={live}
          toggleLiveMode={toggleLiveMode}
          recordingPolicy={recordingPolicy}
          displayedCameraList={displayedCameraList}
          displayVideoPlayers={displayVideoPlayers}
        />
      </Grid>
      <Grid item xs={12}>
        {!!showFilters && (
          <FilterPanelComponent
            allowFilter={allowFilter}
            durationWarning={durationWarning}
            futureTimeWarning={futureTimeWarning}
            recordingPolicyWarning={recordingPolicyWarning}
            handleClearFilter={handleClearFilter}
            handleSetFilters={handleSetFilters}
            handleSetVideoDateFromState={handleSetVideoDateFromState}
            handleSetVideoDateToState={handleSetVideoDateToState}
            handleSetVideoTimeFromState={handleSetVideoTimeFromState}
            handleSetVideoTimeToState={handleSetVideoTimeToState}
            recordingPolicy={recordingPolicy}
            today={today}
            videoDateFromState={videoDateFromState}
            videoDateToState={videoDateToState}
            videoTimeFromState={videoTimeFromState}
            videoTimeToState={videoTimeToState}
          />
        )}
      </Grid>
    </Grid>
  );
};

ButtonsFiltersContainer.propTypes = {
  live: PropTypes.bool.isRequired,
  handleApplyFilter: PropTypes.func.isRequired,
  recordingPolicy: PropTypes.any,
  today: PropTypes.object.isRequired,
  handleSetLive: PropTypes.func.isRequired
};

export default ButtonsFiltersContainer;
