import React, { useCallback, useEffect, useState } from "react";
import { postViewLinkService } from "../../../services/commonServices.js";
import { useSelector } from "react-redux";
import ModalComponent from "./ModalComponent.jsx";
import { BUTTON } from "./button/buttonConstants.js";
import { styles } from "./ViewLinkModalStyles.js";
import { FormattedMessage, useIntl } from "react-intl";
import format from "date-fns/format";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { addDays, addMonths } from "date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
  ALLOWED_TYPING_KEYS,
  I18N_CONFIG,
  LOCAL_STORAGE__CUSTOMER_ID,
  LOCAL_STORAGE__LOCALE
} from "../../../utils/constants.js";
import { getCurrentTenant, getLanguage } from "../../../utils/utils.js";
import { es, enUS } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { showMessage } from "../../shared/notificationSnack/NotificationSnack.js";
import { APP_BASE_ROUTE } from "../../../utils/routes";
import Spinner from "../../shared/loading/components/Spinner";

const ViewLinkModalComponent = ({ handleClose }) => {
  const intl = useIntl();
  const useStyles = makeStyles(theme => styles(theme));
  const classes = useStyles();
  const locale = localStorage.getItem(LOCAL_STORAGE__LOCALE) || getLanguage();
  const customerId = localStorage.getItem(LOCAL_STORAGE__CUSTOMER_ID);
  const currentLang = locale === I18N_CONFIG.es.title ? es : enUS;
  const { deployment_id } = useParams();
  const today = new Date();
  const minDateToFilter = addDays(today, 1);
  const maxDate = addMonths(minDateToFilter, 1);

  const [loading, setLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [viewLinkOptions, setViewLinkOptions] = useState({
    description: "",
    cameras: [],
    date: minDateToFilter,
    pin: ""
  });
  const [viewLink, setViewLink] = useState("");

  const storedCameraList = JSON.parse(localStorage.getItem(deployment_id));
  const cameraList =
    useSelector(state => state.commonState.cameraList[deployment_id]) ||
    storedCameraList;
  const viewLinkCamera = useSelector(
    state => state.commonState.viewLinkModalSelected
  );

  const handleOpenMosaic = () => {
    setOpenSelect(true);
  };

  const handleCloseMosaic = () => {
    setOpenSelect(false);
  };

  useEffect(() => {
    if (!!viewLinkCamera) {
      selectedCameras.push(viewLinkCamera);
      handleChangeViewLinkOptions({
        target: { name: "cameras", value: selectedCameras }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewLinkCamera]);

  const handleChangeCameras = event => {
    const { value } = event.target;

    if (value.find(cam => cam === "all")) {
      if (selectedCameras.length === cameraList.length) {
        setSelectedCameras([]);
      } else {
        setSelectedCameras(cameraList.map(cam => cam._id));
      }
    } else {
      const selected = value.filter(cam => cam !== undefined);
      if (selected.length > 0) {
        setSelectedCameras(selected);
        handleChangeViewLinkOptions(event);
      }
    }
  };

  const checkedCamera = camera => {
    return selectedCameras.filter(cam => cam === camera).length > 0;
  };

  const handleChangePin = event => {
    const { value } = event.target;
    if (!/^\d*$/.test(value) || value.length > 4) {
      return;
    }
    handleChangeViewLinkOptions(event);
  };

  const handleChangeDate = value => {
    setViewLinkOptions({
      ...viewLinkOptions,
      date: value
    });
  };

  const handleChangeViewLinkOptions = useCallback(
    event => {
      const { name, value } = event.target;

      setViewLinkOptions({
        ...viewLinkOptions,
        [name]: value
      });
    },
    [viewLinkOptions]
  );

  useEffect(() => {
    setIsSaveDisabled(
      (!viewLinkOptions?.description ||
        viewLinkOptions?.cameras?.length === 0 ||
        (viewLinkOptions?.pin?.length !== 4 &&
          viewLinkOptions?.pin?.length !== 0)) &&
        !viewLink
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewLinkOptions]);

  const createViewLink = () => {
    const body = {
      streamsource_ids: selectedCameras,
      description: viewLinkOptions.description,
      expiration_date: +format(viewLinkOptions.date, "t"),
      ...(!!viewLinkOptions.pin && { pin: viewLinkOptions.pin }),
      customer_id: customerId
    };

    setLoading(true);
    postViewLinkService(body, postCreateViewLinkCallback);
  };

  const postCreateViewLinkCallback = response => {
    const url = `${window.location.origin}${APP_BASE_ROUTE.replace(
      ":tenant",
      getCurrentTenant()
    )}/viewer/${response.data._id}`;

    setViewLink(url);

    navigator.clipboard.writeText(url);

    showMessage(
      intl.formatMessage({ id: "view_link_modal__link_copied" }),
      "success"
    );
    setLoading(false);
  };

  const body = (
    <div className={classes.linkForm}>
      <div>
        <h2>{intl.formatMessage({ id: "view_link_modal__title" })}</h2>
        {loading && (
          <h4>{intl.formatMessage({ id: "view_link_modal__subtitle" })}</h4>
        )}
      </div>
      {loading ? (
        <div className={classes.spinnerContainer}>
          <Spinner />
        </div>
      ) : !!viewLink ? (
        <>
          <h4>{intl.formatMessage({ id: "view_link_modal__link_created" })}</h4>
          <div className={classes.viewLinkContainer}>
            <TextField
              value={""}
              variant="outlined"
              fullWidth
              disabled
              InputProps={{
                startAdornment: (
                  <Link
                    className={classes.viewLink}
                    href={viewLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "primary" }}
                  >
                    {viewLink}
                  </Link>
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      showMessage(
                        intl.formatMessage({
                          id: "view_link_modal__link_copied"
                        }),
                        "success"
                      );
                      navigator.clipboard.writeText(viewLink);
                    }}
                  >
                    <FileCopyIcon />
                  </IconButton>
                )
              }}
            />
          </div>
        </>
      ) : (
        <FormControl variant="filled" className={classes.viewLinkModalContents}>
          <div className={classes.viewLinkModalOption}>
            <Typography>
              <FormattedMessage id="view_link_modal__description" />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="view_link_modal__description_subtitle" />
            </Typography>
            <TextField
              name="description"
              onChange={handleChangeViewLinkOptions}
              value={viewLinkOptions?.description}
              fullWidth
              required
              variant="outlined"
              multiline={true}
            />
          </div>
          <div className={classes.viewLinkModalOption}>
            <Typography>
              <FormattedMessage id="view_link_modal__cameras" />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="view_link_modal__cameras_subtitle" />
            </Typography>
            <Select
              name="cameras"
              open={openSelect}
              onOpen={handleOpenMosaic}
              onClose={handleCloseMosaic}
              disableUnderline
              renderValue={selected => {
                const text =
                  selected.length === 1
                    ? intl.formatMessage({ id: "selected_camera" })
                    : intl.formatMessage({ id: "selected_cameras" });
                return selected.length + " " + text;
              }}
              value={selectedCameras}
              onChange={handleChangeCameras}
              id={"camera-simple-select-filled"}
              labelId="camera-simple-select-filled-label"
              multiple
              className={classes.select}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              <MenuItem value="all" className={classes.menuItem}>
                <ListItemIcon>
                  <Checkbox
                    className={classes.checkbox}
                    checked={
                      cameraList.length > 0 &&
                      selectedCameras.length === cameraList.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
              <InputLabel htmlFor="camera-simple-select-filled-label">
                {intl.formatMessage({ id: "select_cameras" })}
              </InputLabel>
              {cameraList &&
                cameraList.map(camera => (
                  <MenuItem
                    key={camera._id}
                    value={camera._id}
                    className={classes.menuItem}
                  >
                    <Checkbox
                      checked={checkedCamera(camera._id)}
                      className={classes.checkbox}
                    />
                    {camera.description}
                  </MenuItem>
                ))}
              <Divider />
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.secondaryButton}
                  fullWidth
                  onClick={handleCloseMosaic}
                  disabled={selectedCameras.length === 0}
                  classes={{ disabled: classes.disabled }}
                >
                  <Typography variant={"button"}>
                    {intl.formatMessage({ id: "apply_filters" })}
                  </Typography>
                </Button>
              </div>
            </Select>
          </div>
          <div className={classes.datePinContainer}>
            <div className={classes.viewLinkModalOption}>
              <Typography>
                <FormattedMessage id="view_link_modal__date" />
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="view_link_modal__date_subtitle" />
              </Typography>
              <MuiPickersUtilsProvider
                locale={currentLang}
                utils={DateFnsUtils}
              >
                <KeyboardDatePicker
                  name="date"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-to"
                  label={intl.formatMessage({ id: "date_to" })}
                  value={viewLinkOptions?.date}
                  onChange={handleChangeDate}
                  minDate={minDateToFilter}
                  maxDate={maxDate}
                  okLabel={intl.formatMessage({ id: "accept" })}
                  cancelLabel={intl.formatMessage({ id: "cancel" })}
                  maxDateMessage={intl.formatMessage({
                    id: "max_date_message"
                  })}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.viewLinkModalOption}>
              <Typography>
                <FormattedMessage id="view_link_modal__pin" />
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="view_link_modal__pin_placeholder" />
              </Typography>
              <TextField
                  name="pin"
                  onKeyDown={(e) => {
                    if (!/^\d$/.test(e.key) && !ALLOWED_TYPING_KEYS.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 4) {
                      handleChangePin(e);
                    }
                  }}
                  value={viewLinkOptions?.pin}
                  placeholder="Introduce tu PIN"
                  fullWidth
                  variant="outlined"
                  type="text"
                  inputProps={{ maxLength: 4 }}
              />
            </div>
          </div>
        </FormControl>
      )}
    </div>
  );

  return (
    <ModalComponent
      buttonAcceptText={
        !viewLink
          ? intl.formatMessage({ id: "accept" })
          : intl.formatMessage({ id: "close" })
      }
      buttonCancelText={intl.formatMessage({ id: "cancel" })}
      buttonCancelVariant={BUTTON.VARIANT_SECONDARY}
      handleSave={!viewLink ? createViewLink : handleClose}
      saveDisabled={isSaveDisabled}
      handleClose={handleClose}
      displayCloseButton={!viewLink}
      body={body}
    />
  );
};

export default ViewLinkModalComponent;
