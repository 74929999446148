import React, { useCallback, useEffect, useState } from "react";
import {
  getOverlay,
  removeOverlay,
  setOverlay
} from "../../../services/commonServices";
import { useSelector } from "react-redux";
import ModalComponent from "./ModalComponent";
import { styles } from "./OverlayModalStyles.js";
import { FormattedMessage, useIntl } from "react-intl";
import CallMadeOutlinedIcon from "@material-ui/icons/CallMadeOutlined";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import Spinner from "../../shared/loading/components/Spinner";

const OverlayModalComponent = ({ handleClose }) => {
  const intl = useIntl();
  const useStyles = makeStyles(theme => styles(theme));
  const classes = useStyles();

  const cameraId = useSelector(state => state.commonState.cameraSelected);

  const [loading, setLoading] = useState(false);

  const [supportsStyling, setSupportsStyling] = useState(false);

  const [overlayOptions, setOverlayOptions] = useState({});

  const getCurrentOverlayCallback = response => {
    let currentOverlay = {};
    let currentOverlayParams = {};

    setSupportsStyling(!!response.data?.parameters?.supports_styling);

    currentOverlayParams.additional_text =
      response.data?.parameters?.additional_text || "";
    currentOverlayParams.date_time =
      response.data?.parameters?.has_datetime !== undefined
        ? response.data.parameters.has_datetime
        : true;
    currentOverlayParams.text_position =
      response.data?.parameters?.position || "topLeft";
    currentOverlayParams.enabled = !!response.data?.enabled;

    currentOverlay.params = currentOverlayParams;

    setOverlayOptions(currentOverlay);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getOverlay(cameraId, getCurrentOverlayCallback);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOverlayCallback = () => {
    handleClose();
  };

  const saveOverlayOptions = () => {
    if (overlayOptions?.params?.enabled) {
      setOverlay(cameraId, overlayOptions, setOverlayCallback);
    } else {
      removeOverlay(cameraId, setOverlayCallback);
    }
  };

  const handleChangeOverlayForm = useCallback(
    event => {
      let inputType = event.target.type;
      switch (inputType) {
        case "checkbox":
          setOverlayOptions({
            params: {
              ...overlayOptions.parameters,
              ...overlayOptions.params,
              [event.target.name]: event.target.checked
            }
          });
          break;
        case "textarea":
        case "text":
          setOverlayOptions({
            params: {
              ...overlayOptions.parameters,
              ...overlayOptions.params,
              [event.target.name]: event.target.value
            }
          });
          break;
        case "radio":
          setOverlayOptions({
            params: {
              ...overlayOptions.parameters,
              ...overlayOptions.params,
              [event.target.name]: event.target.value
            }
          });
          break;
        default:
          setOverlayOptions({
            params: {
              ...overlayOptions.parameters,
              ...overlayOptions.params,
              [event.target.name]: event.target.checked
            }
          });
          break;
      }
    },
    [overlayOptions]
  );

  const body = (
    <div>
      <div>
        <h2>{intl.formatMessage({ id: "video_overlay_modal_title" })}</h2>
      </div>
      {loading ? (
        <div className={classes.spinnerContainer}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.overlayFormGrid}>
          <div
            className={`${classes.overlayFormLabels} ${classes.overlayFormRow1}`}
          >
            <Typography>
              <FormattedMessage id="video_overlay_modal_activate" />
            </Typography>
          </div>
          {overlayOptions?.params?.enabled ? (
            <>
              <div
                className={`${classes.overlayFormInputs} ${classes.overlayFormRow1}`}
              >
                <FormControlLabel
                  label=""
                  control={
                    <Switch
                      color="primary"
                      checked={overlayOptions?.params?.enabled}
                      onChange={handleChangeOverlayForm}
                      name="enabled"
                    />
                  }
                />
              </div>
              <div
                className={`${classes.overlayFormLabels} ${classes.overlayFormRow2}`}
              >
                <Typography>
                  <FormattedMessage id="video_overlay_modal_datetime" />
                </Typography>
              </div>
              <div
                className={`${classes.overlayFormInputs} ${classes.overlayFormRow2}`}
              >
                <FormControlLabel
                  label=""
                  control={
                    <Switch
                      color="primary"
                      checked={overlayOptions?.params.date_time}
                      onChange={handleChangeOverlayForm}
                      name="date_time"
                    />
                  }
                />
              </div>
              <div
                className={`${classes.overlayFormLabels} ${classes.overlayFormRow3}`}
              >
                <Typography>
                  <FormattedMessage id="video_overlay_modal_anchor" />
                </Typography>
              </div>
              <div
                className={`${classes.overlayFormInputs} ${classes.overlayFormRow3}`}
              >
                <RadioGroup
                  name="text_position"
                  className={classes.radioGroup}
                  value={overlayOptions?.params?.text_position}
                  onChange={handleChangeOverlayForm}
                >
                  <FormControlLabel
                    label=""
                    value="topLeft"
                    className={classes.radioButton}
                    control={
                      <Radio
                        icon={
                          <CallMadeOutlinedIcon
                            className={`${classes.radioIconUnchecked} ${classes.rotate270}`}
                          />
                        }
                        checkedIcon={
                          <CallMadeOutlinedIcon
                            className={`${classes.radioIconChecked} ${classes.rotate270}`}
                          />
                        }
                      />
                    }
                  />
                  {supportsStyling && (
                    <FormControlLabel
                      label=""
                      value="topRight"
                      className={classes.radioButton}
                      control={
                        <Radio
                          icon={
                            <CallMadeOutlinedIcon
                              className={classes.radioIconUnchecked}
                            />
                          }
                          checkedIcon={
                            <CallMadeOutlinedIcon
                              className={classes.radioIconChecked}
                            />
                          }
                        />
                      }
                    />
                  )}
                  {supportsStyling && (
                    <FormControlLabel
                      label=""
                      value="bottomRight"
                      className={classes.radioButton}
                      control={
                        <Radio
                          icon={
                            <CallMadeOutlinedIcon
                              className={`${classes.radioIconUnchecked} ${classes.rotate90}`}
                            />
                          }
                          checkedIcon={
                            <CallMadeOutlinedIcon
                              className={`${classes.radioIconChecked} ${classes.rotate90}`}
                            />
                          }
                        />
                      }
                    />
                  )}
                  <FormControlLabel
                    label=""
                    value="bottomLeft"
                    className={classes.radioButton}
                    control={
                      <Radio
                        icon={
                          <CallMadeOutlinedIcon
                            className={`${classes.radioIconUnchecked} ${classes.rotate180}`}
                          />
                        }
                        checkedIcon={
                          <CallMadeOutlinedIcon
                            className={`${classes.radioIconChecked} ${classes.rotate180}`}
                          />
                        }
                      />
                    }
                  />
                </RadioGroup>
              </div>
              <div
                className={`${classes.overlayFormLabels} ${classes.overlayFormRow4}`}
              >
                <Typography>
                  <FormattedMessage id="video_overlay_modal_text" />
                </Typography>
              </div>
              <div
                className={`${classes.overlayFormInputs} ${classes.overlayFormRow4} ${classes.textAreaContainer}`}
              >
                <TextField
                  name="additional_text"
                  onChange={handleChangeOverlayForm}
                  placeholder={intl.formatMessage({
                    id: "video_overlay_modal_text_placeholder"
                  })}
                  value={overlayOptions?.params?.additional_text}
                  fullWidth
                  variant="outlined"
                  multiline={supportsStyling}
                />
              </div>
            </>
          ) : (
            <div
              className={`${classes.overlayFormInputs} ${classes.overlayFormRow1}`}
            >
              <FormControlLabel
                label=""
                control={
                  <Switch
                    color="primary"
                    checked={overlayOptions?.params?.enabled}
                    onChange={handleChangeOverlayForm}
                    name="enabled"
                  />
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <ModalComponent
      buttonAcceptText={intl.formatMessage({ id: "accept" })}
      buttonCancelText={intl.formatMessage({ id: "cancel" })}
      handleSave={saveOverlayOptions}
      saveDisabled={
        (!overlayOptions?.params?.additional_text ||
          !overlayOptions?.params?.additional_text.replace(/\s+/g, "")) &&
        !overlayOptions?.params?.date_time &&
        overlayOptions?.params?.enabled
      }
      handleClose={handleClose}
      body={body}
    />
  );
};

export default OverlayModalComponent;
