import {
    TURN_OFF_LOADING_ACTION,
    TURN_ON_LOADING_ACTION,
    FILTER_CAMERA_MOSAIC_ACTION,
    PUT_CAMERA_MOSAIC_ACTION,
    SET_STYLES_ACTION,
    SET_PERMISSIONS_ACTION,
    SET_USER_CREATION_ROLES_ACTION,
    SET_SECONDS_ACTION,
    SET_PLAYING_ACTION,
    SET_REWIND_ACTION,
    SET_FAST_FORWARD_ACTION,
    SET_CAMERA_REFERENCE_ACTION,
    SET_SELECTED_ALERT_ACTION,
    SET_ALERTS_ACTION,
    SET_CAMERA_SELECTED_ACTION,
    SET_VIEW_LINK_MODAL_SELECTED_ACTION
} from '../actions/actionTypes';
import {INITIAL_SECONDS, LOADING_PERMISSIONS} from "../utils/constants";

const INITIAL_STATE = {
    cameraList: {},
    customerStyles: null,
    fastForward: false,
    filteredCameraList: {},
    language: navigator.language.split(/[-_]/)[0],
    loading: false,
    playing: true,
    rewind: false,
    seconds: INITIAL_SECONDS,
    stylesStored: false,
    cameraReference: null,
    selectedAlert: null,
    alerts: null,
    cameraSelected: null,
    viewLinkModalSelected: null,
    permissions: LOADING_PERMISSIONS,
};

function commonState(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TURN_ON_LOADING_ACTION: {
            return {...state, loading: true};
        }
        case TURN_OFF_LOADING_ACTION: {
            return {...state, loading: false};
        }
        case FILTER_CAMERA_MOSAIC_ACTION: {
            return {
                ...state, filteredCameraList: {[action.data.deployment_id]: action.data.filteredCameraList},
            };
        }
        case PUT_CAMERA_MOSAIC_ACTION: {
            return {
                ...state,
                cameraList: {...state.cameraList, [action.data.deployment_id]: action.data.cameraList},
            };
        }
        case SET_STYLES_ACTION : {
            return {...state, customerStyles: action.data, stylesStored: true}
        }
        case SET_PERMISSIONS_ACTION : {
            return {...state, permissions: action.data}
        }
        case SET_USER_CREATION_ROLES_ACTION : {
            return {...state, manageableUserRoles: action.data}
        }
        case SET_SECONDS_ACTION: {
            return {...state, seconds: action.data.seconds}
        }
        case SET_PLAYING_ACTION: {
            return {...state, playing: action.data.playing}
        }
        case SET_REWIND_ACTION: {
            return {...state, rewind: action.data.rewind}
        }
        case SET_FAST_FORWARD_ACTION: {
            return {...state, fastForward: action.data.fastForward}
        }
        case SET_CAMERA_REFERENCE_ACTION: {
            return {...state, cameraReference: action.data.cameraReference}
        }
        case SET_SELECTED_ALERT_ACTION: {
            return {...state, selectedAlert: action.data.selectedAlert}
        }
        case SET_ALERTS_ACTION: {
            return {...state, alerts: action.data}
        }
        case SET_CAMERA_SELECTED_ACTION: {
            return {...state, cameraSelected: action.data}
        }
        case SET_VIEW_LINK_MODAL_SELECTED_ACTION: {
            return {...state, viewLinkModalSelected: action.data}
        }
        default:
            return state;
    }
}

export default commonState;
