import React from "react";
import { Button, ButtonGroup, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import MovieCreationRoundedIcon from "@material-ui/icons/MovieCreationRounded";
import {
  tenantPrimaryButton,
  tenantPrimaryButtonNoHover,
  tenantSecondaryButton
} from "../../../../utils/tenantStyles";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FullscreenRoundedIcon from "@material-ui/icons/FullscreenRounded";
import Grid from "@material-ui/core/Grid";
import { getTimeConversionFn } from "../../../../utils/utils";
import CameraComboComponent from "./components/CameraComboComponent";
import MosaicComboComponent from "./components/MosaicComboComponent";
import AuthorizedElement from "../../../shared/auth/AuthorizedElement";
import { PERMISSION_RECORDING_VIEW } from "../../../../utils/constants";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 7,
    width: "100%",
    "&  div:first-child": {
      marginBottom: "0 !important",
      "& button": {
        height: "100%"
      }
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4)
    }
  },
  buttonGroup: {
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  buttonIcon: {
    marginRight: 5
  },
  primaryButton: tenantPrimaryButtonNoHover(theme),
  primaryButtonMosaic: tenantPrimaryButton(theme),
  secondaryButton: tenantSecondaryButton(theme),
  textButton: {
    marginTop: 5
  },
  disabled: {
    backgroundColor: theme.palette.disabled.light + "!important",
    border: 0 + "!important",
    color: theme.palette.disabled.main + "!important",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  videoTextContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(4),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: theme.spacing(1)
    }
  },
  videoStatus: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: theme.palette.primary.main
  },
  formControl: {
    minWidth: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  buttonFlexContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(3)
    }
  },
  comboContainer: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3)
    }
  },
  mosaicComboContainer: {
    display: "flex",
    alignItems: "flex-end",
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  recPolicy: {
    color: "gray",
    fontSize: 12
  },
  videoType: {
    color: "gray",
    fontSize: 12
  }
}));

const ButtonGroupMosaicComponent = ({
  toggleLiveMode,
  live,
  recordingPolicy,
  handleMosaic,
  displayVideoPlayers
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { deployment_id, mosaic_id } = useParams();
  const storedCameraList = JSON.parse(localStorage.getItem(deployment_id));
  const cameraList =
    useSelector(state => state.commonState.cameraList[deployment_id]) ||
    storedCameraList;

  return (
    <Grid container className={classes.container}>
      {!mosaic_id && (
        <Grid item lg={6} md={12} className={classes.buttonFlexContainer}>
          <ButtonGroup
            className={classes.buttonGroup}
            variant="contained"
            color={"secondary"}
          >
            <Button
              startIcon={<SettingsInputAntennaIcon />}
              variant={"contained"}
              className={
                !live ? classes.secondaryButton : classes.primaryButton
              }
              onClick={() => toggleLiveMode(true)}
            >
              <Typography variant={"body2"}>
                {intl.formatMessage({ id: "stream_live_go_to" })}
              </Typography>
            </Button>
            <AuthorizedElement permission={PERMISSION_RECORDING_VIEW}>
              <Button
                startIcon={<MovieCreationRoundedIcon />}
                variant={"contained"}
                className={
                  live ? classes.secondaryButton : classes.primaryButton
                }
                onClick={() => toggleLiveMode(false)}
              >
                <Typography variant={"body2"}>
                  {" "}
                  {intl.formatMessage({ id: "show_filters" })}
                </Typography>
              </Button>
            </AuthorizedElement>
          </ButtonGroup>
          {cameraList && !mosaic_id && (
            <Grid item className={classes.comboContainer}>
              <CameraComboComponent />
            </Grid>
          )}
        </Grid>
      )}
      <Grid
        item
        container
        lg={!mosaic_id ? 6 : 12}
        md={12}
        className={classes.videoTextContainer}
      >
        {!mosaic_id && (
          <Grid item>
            <div className={classes.videoStatus}>
              <div className={classes.recPolicy}>
                {intl.formatMessage({ id: "rec_policy" })}
              </div>
              <div>
                {recordingPolicy
                  ? getTimeConversionFn(recordingPolicy.window, intl)
                  : "-"}
              </div>
            </div>
          </Grid>
        )}
        <Grid item className={classes.mosaicComboContainer}>
          {mosaic_id && <MosaicComboComponent mosaicId={mosaic_id} />}
          <Button
            startIcon={<FullscreenRoundedIcon />}
            onClick={handleMosaic.enter}
            variant={"contained"}
            className={
              !displayVideoPlayers
                ? classes.disabled
                : classes.primaryButtonMosaic
            }
            disabled={!displayVideoPlayers}
          >
            <Typography variant={"body2"}>
              {intl.formatMessage({ id: "mosaic_fullscreen" })}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

ButtonGroupMosaicComponent.propTypes = {
  toggleLiveMode: PropTypes.func.isRequired,
  live: PropTypes.bool.isRequired,
  recordingPolicy: PropTypes.any,
  handleMosaic: PropTypes.any.isRequired
};

export default ButtonGroupMosaicComponent;
