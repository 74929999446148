import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./SettingsIconStyles";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";

const useStyles = makeStyles(theme => styles(theme));

const SettingsIconComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SettingsRoundedIcon className={classes.img} />
    </div>
  );
};

export default SettingsIconComponent;
