import {tenantPrimaryButton, tenantSecondaryButton} from "../../../utils/tenantStyles";

export const styles = theme => ({
    textFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6),
        padding: theme.spacing(3, 4),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: theme.spacing(4),
            alignItems: 'center',
            padding: theme.spacing(1)
        }
    },
    textFieldBlock: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(12),
        width: 'fit-content',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: theme.spacing(4),
            alignItems: 'center',
            width: '100%'
        }
    },
    textField: {
        minWidth: '14rem',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    dateField: {
        minWidth: '14rem',
        maxWidth: '70px',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    formFooter: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3)
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(4)
    },
    primaryButton: tenantPrimaryButton(theme),
    secondaryButton: tenantSecondaryButton(theme),
    disabled: {
        backgroundColor: theme.palette.disabled.light + '!important',
        border: 0 + '!important',
        color: theme.palette.disabled.main + '!important'
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center'
    }
});
