import {
  tenantPrimaryButton,
  tenantSecondaryButton
} from "../../../utils/tenantStyles";
import { GRAY } from "../../../utils/constants";

export const styles = (theme, cameraList, videoSize) => ({
  cardContent: {
    position: "relative"
  },
  cardHeader: {
    backgroundColor: theme.palette.white.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
    boxShadow: "4px 4px 20px rgba(38, 37, 37, 0.1)",
    color: GRAY
  },
  cameraButtonsContainer: {
    display: "flex",
    gap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },
  cameraHandlingSwitch: {
    display: "flex",
    alignItems: "center"
  },
  primaryButton: tenantPrimaryButton(theme),
  secondaryButton: tenantSecondaryButton(theme),
  cameraStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  ellipsis: {
    textAlign: "left",
    maxWidth: 150,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.only("lg")]: {
      maxWidth: 130
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 100
    }
  },
  tooltip: {
    backgroundColor: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.white.main
    }
  },
  iconButton: {
    [theme.breakpoints.only("lg")]: {
      padding: theme.spacing(0.5)
    }
  },
  iconMainColor: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    "&:hover": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%"
    }
  },
  iconExtraPadding: {
    padding: "1.5px"
  },
  radioIconUnchecked: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    margin: "0px"
  },
  radioIconChecked: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    margin: "0px"
  },
  radioButton: {
    marginLeft: 0,
    marginRight: 0
  },
  rotate270: {
    transform: "rotate(270deg)"
  },
  rotate180: {
    transform: "rotate(180deg)"
  },
  rotate90: {
    transform: "rotate(90deg)"
  },
  radioGroup: {
    flexDirection: "row",
    margin: "0px",
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
      marginRight: 0
    }
  },
  textAreaContainer: {
    width: "80%"
  },
  iconNegative: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%"
  },
  iconActivatedColor: {
    color: "green"
  },
  iconDeactivatedColor: {
    color: "red"
  },
  iconDisabledColor: {
    color: "#a1a1a1"
  },
  videoCard: {
    boxShadow: "4px 4px 20px rgba(38, 37, 37, 0.1)"
  },
  videoCardMosaic: {
    height: videoSize
  },
  fullScreenComponent: {
    display: "flex",
    justifyContent: "center"
  },
  videoStatus: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    height: 40,
    padding: 0,
    margin: 0
  },
  presetsCard: {
    marginBottom: theme.spacing(1),
    boxShadow: "4px 4px 20px rgba(38, 37, 37, 0.1)",
    "&:before": {
      display: "none"
    }
  },
  presetsRightBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2)
  },
  switchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});
