import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import PaginationComponent from "../../common/PaginationComponent";
import TemporaryCameraCardComponent from "./TemporaryCameraCardComponent";
import { setCameraReferenceActionCreator } from "../../../actions/commonActions";
import {
  CAMERA_STATUS_OK,
  CAMERAS_PER_PAGE,
  KEEP_ALIVE_TIMEOUT
} from "../../../utils/constants";
//import { updateKeepAliveByMosaicService } from "../../../services/commonServices";

const TemporaryCamerasCardListComponent = ({
  classes,
  viewLinkCameras,
  mosaicId
}) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [camerasList, setCamerasList] = useState(null);
  const [gridSize, setGridSize] = useState(null);
  const [displayedCameraList, setDisplayedCameraList] = useState(
    viewLinkCameras
  );

  const setCameraReference = useCallback(
    data => {
      dispatch(setCameraReferenceActionCreator(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (viewLinkCameras.length > 0) {
      setDisplayedCameraList(viewLinkCameras);
    }
    // eslint-disable-next-line
  }, [viewLinkCameras]);

  useEffect(() => {
    if (displayedCameraList.length > 0) {
      for (const camera of displayedCameraList) {
        if (camera?.status?.last_status?.toLowerCase() === CAMERA_STATUS_OK) {
          setCameraReference({ cameraReference: camera._id });
        }
      }
    }
  }, [displayedCameraList, setCameraReference]);

  const onPageChanged = data => {
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    setCurrentPage(currentPage);
    setCamerasList(displayedCameraList.slice(offset, offset + pageLimit));
    handleSetGrid(displayedCameraList.slice(offset, offset + pageLimit));
  };

  const loadPagination = useCallback(() => {
    const paginationData = {
      currentPage: 1,
      totalPages: Math.ceil(displayedCameraList.length / CAMERAS_PER_PAGE),
      pageLimit: CAMERAS_PER_PAGE,
      totalRecords: displayedCameraList.length
    };
    const { currentPage, pageLimit } = paginationData;
    const offset = (currentPage - 1) * pageLimit;
    setCurrentPage(currentPage);
    setCamerasList(displayedCameraList.slice(offset, offset + pageLimit));
    handleSetGrid(displayedCameraList.slice(offset, offset + pageLimit));
  }, [displayedCameraList]);

  useEffect(() => {
    loadPagination();
  }, [loadPagination]);

  useEffect(() => {
    //updateKeepAliveByMosaicService(mosaicId);

    let secondsInterval = null;
    secondsInterval = setInterval(() => {
      //updateKeepAliveByMosaicService(mosaicId);
    }, KEEP_ALIVE_TIMEOUT);

    return () => clearInterval(secondsInterval);
  }, [mosaicId]);

  const totalPages = Math.ceil(displayedCameraList?.length / CAMERAS_PER_PAGE);

  const handleSetGrid = cameras => {
    switch (cameras.length) {
      case 1:
        setGridSize(12);
        break;
      case 2:
        setGridSize(6);
        break;
      default:
        setGridSize(4);
        break;
    }
  };

  return (
    <>
      <Grid container spacing={2} className={classes.camerasContainer}>
        {camerasList?.map(camera => (
          <Grid
            item
            sm={12}
            md={camerasList.length > 1 ? 6 : 12}
            lg={gridSize}
            key={camera._id}
          >
            <TemporaryCameraCardComponent
              camera={camera}
              gridSize={gridSize}
              camerasList={camerasList}
            />
          </Grid>
        ))}
        {displayedCameraList.length > CAMERAS_PER_PAGE && (
          <Grid container item className={classes.buttonGroup}>
            <div className="d-flex flex-row py-4 align-items-center">
              <PaginationComponent
                totalRecords={displayedCameraList.length}
                pageLimit={CAMERAS_PER_PAGE}
                onPageChanged={onPageChanged}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

TemporaryCamerasCardListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  viewLinkCameras: PropTypes.object.isRequired,
  mosaicId: PropTypes.string.isRequired
};

export default TemporaryCamerasCardListComponent;
