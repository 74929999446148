import Keycloak from "keycloak-js";
import {
  KEYCLOACK_DEFAULT_CLIENTID,
  KEYCLOAK_REALM,
  KEYCLOAK_TENANT_CLIENTIDS,
  KEYCLOAK_URL
} from "./constants";
import { getTenantFromUrl } from "./utils";

const getTenantClientId = () => {
  const tenantMatch = getTenantFromUrl();
  if (tenantMatch && KEYCLOAK_TENANT_CLIENTIDS.hasOwnProperty(tenantMatch)) {
    return KEYCLOAK_TENANT_CLIENTIDS[tenantMatch];
  }
  return KEYCLOACK_DEFAULT_CLIENTID;
};

const tenant = getTenantClientId();
const keycloakConfig = {
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: tenant
};

const keycloak = new Keycloak(keycloakConfig);

export const keycloakInitConfig = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: `${window.location.origin}/tiivii-supervision/silent-check-sso.html`,
  pkceMethod: "S256"
};

export const hasRole = role =>
  keycloak
    ? keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role)
    : false;

export const hasRoles = roles =>
  keycloak
    ? roles.some(r => keycloak.hasRealmRole(r) || keycloak.hasResourceRole(r))
    : false;

export default keycloak;
