import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import VideoHlsComponent from "../../shared/VideoHlsComponent";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import {
  setPlayingActionCreator,
  setSecondsActionCreator
} from "../../../actions/commonActions";
import { storeVideoDimensionsActionCreator } from "../../../actions/ptzActions";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  videoContainer: {
    position: "relative"
  },
  image: {
    userSelect: "none"
  }
}));

const TemporaryVideoComponent = ({
  poster,
  live,
  camera,
  detail,
  videoSize,
  fullScreen
}) => {
  const classes = useStyles();
  const videoRef = useRef();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const cameraReference = useSelector(
    state => state.commonState.cameraReference
  );
  const seconds = useSelector(state => state.commonState.seconds);
  const rewind = useSelector(state => state.commonState.rewind);
  const fastForward = useSelector(state => state.commonState.fastForward);
  const playing = useSelector(state => state.commonState.playing);

  const storeVideoDimensions = data => {
    dispatch(storeVideoDimensionsActionCreator(data));
  };

  const setPlaying = useCallback(
    data => {
      dispatch(setPlayingActionCreator({ playing: data }));
    },
    [dispatch]
  );

  const setSeconds = data => {
    dispatch(setSecondsActionCreator({ seconds: data }));
  };

  return (
    <div className={classes.videoContainer}>
      <VideoHlsComponent
        videoRef={videoRef}
        authorization={keycloak}
        mute={false}
        playing={playing}
        setPlaying={setPlaying}
        rewind={rewind}
        fastForward={fastForward}
        poster={poster}
        video={camera.temporary_link}
        storeSeconds={seconds}
        setSeconds={setSeconds}
        live={live}
        detail={detail}
        camera={camera}
        cameraReference={cameraReference}
        videoSize={videoSize}
        fullScreen={fullScreen}
        storeVideoDimensions={storeVideoDimensions}
      />
    </div>
  );
};

TemporaryVideoComponent.propTypes = {
  poster: PropTypes.string.isRequired,
  filters: PropTypes.any,
  live: PropTypes.bool.isRequired,
  deployment_id: PropTypes.any,
  camera: PropTypes.object
};

export default TemporaryVideoComponent;
