import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import format from 'date-fns/format';
import {getRecordingPolicy} from '../../../../../services/commonServices';
import {
    ALERT_TIME_RANGE_VALUE,
    BREADCRUMB_MOSAIC,
    BREADCRUMB_MOSAIC_AND_CAMERA,
    MENU_AND_FOOTER_HEIGHT,
    PERMISSION_RECORDING_VIEW,
} from '../../../../../utils/constants';
import CamerasCardListComponent from '../../../components/cameraListComponent/CamerasCardListComponent';
import PageHeaderComponent from '../../../../common/pageHeaderComponent/PageHeaderComponent';
import {styles} from './CameraMosaicStyles';
import ButtonsFiltersContainer from '../../../components/buttonsFiltersContainer/ButtonsFiltersContainer';
import TimeLineComponent from "../../../../common/timeLineComponent/TimeLineComponent";
import PropTypes from "prop-types";
import AlertsContainer from '../../../../deploymentListComponent/components/alertsContainer/AlertsContainer';
import FullScreenMosaic from '../fullScreenMosaicComponent/FullScreenMosaic';

import poster from '../../../../../images/loadingV2.gif'
import {FullScreen, useFullScreenHandle} from 'react-full-screen';
import {setSelectedAlertActionCreator} from "../../../../../actions/commonActions";
import AuthorizedElement from '../../../../shared/auth/AuthorizedElement';


const minHeight = window.innerHeight - MENU_AND_FOOTER_HEIGHT;
const useStyles = makeStyles((theme) => styles(theme, {minHeight}));

const CameraMosaicComponent = ({locale, deploymentId, camerasToShow, mosaicId, customerId}) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const today = new Date();
    const handleMosaic = useFullScreenHandle();
    const filteredCameraList = useSelector(state => state.commonState.filteredCameraList)[deploymentId];
    const selectedAlert = useSelector(state => state.commonState.selectedAlert);

    const [live, setLive] = useState(true);
    const [displayedCameraList, setDisplayedCameraList] = useState([]);
    const [recordingPolicy, setRecordingPolicy] = useState(null);
    const [filters, setFilters] = useState({
        dateFrom: format(today, 'yyyy-MM-dd'),
        dateTo: format(today, 'yyyy-MM-dd'),
        timeFrom: format(today, 'T'),
        timeTo: format(today, 'T')
    });

    const [displayVideoPlayers, setDisplayVideoPlayers] = useState(true);

    const handleSetLive = value => {
        setLive(value);
    };

    const handleSetSelectedAlert = data => {
        dispatch(setSelectedAlertActionCreator({selectedAlert: data}))
    };

    const getPolicy = useCallback(() => {
        const getRecordingPolicySuccess = (response) => {
            setRecordingPolicy(response.data);
        };

        mosaicId ?
            getRecordingPolicy(undefined, mosaicId, getRecordingPolicySuccess) :
            getRecordingPolicy(deploymentId, undefined, getRecordingPolicySuccess);

    }, [deploymentId, mosaicId]);

    const handleSetDisplayVideoPlayers = value => {
        setDisplayVideoPlayers(value)
    };

    const handleApplyFilter = (formattedFilters) => {
        setFilters(formattedFilters);
        setDisplayVideoPlayers(true);
    };

    useEffect(() => {
        if (selectedAlert) {
            handleSetLive(false);

            const now = +format(new Date(), 't');
            const timestamp = selectedAlert.timestamp * 1000;

            const filters = {
                dateFrom: format(timestamp - ALERT_TIME_RANGE_VALUE, 'yyyy-MM-dd'),
                dateTo: format(timestamp + ALERT_TIME_RANGE_VALUE, 'yyyy-MM-dd'),
                timeFrom: format(selectedAlert.timestamp - ALERT_TIME_RANGE_VALUE, 'T'),
                timeTo: format(selectedAlert.timestamp + ALERT_TIME_RANGE_VALUE, 'T'),
            };

            if (filters.timeTo > now) {
                filters.dateTo = format(now * 1000, 'yyyy-MM-dd');
                filters.timeTo = now;
            }

            handleApplyFilter(filters);
            handleSetSelectedAlert(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAlert]);

    useEffect(() => {
        setDisplayedCameraList(filteredCameraList?.length > 0 ? filteredCameraList : camerasToShow);
    }, [filteredCameraList, camerasToShow]);


    useEffect(() => {
        getPolicy();
    }, [getPolicy]);

    return (
        <div className={classes.root}>
            {!handleMosaic.active && <div>
                <PageHeaderComponent type={!!mosaicId ? BREADCRUMB_MOSAIC : BREADCRUMB_MOSAIC_AND_CAMERA}/>
                <Grid container>
                    {recordingPolicy && <ButtonsFiltersContainer
                        live={live}
                        handleSetLive={handleSetLive}
                        handleApplyFilter={handleApplyFilter}
                        recordingPolicy={recordingPolicy}
                        today={today}
                        selectedAlert={selectedAlert}
                        handleMosaic={handleMosaic}
                        handleSetDisplayVideoPlayers={handleSetDisplayVideoPlayers}
                        displayedCameraList={displayedCameraList}
                        displayVideoPlayers={displayVideoPlayers}
                        fullScreen={handleMosaic.active}
                    />}
                    <Grid item xs={12}>
                        {(!live && displayVideoPlayers) &&
                        <TimeLineComponent filters={filters}/>}
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.cameraGrid}>
                    {displayedCameraList.length > 0 && (live || displayVideoPlayers) &&
                    <CamerasCardListComponent mosaicId={mosaicId} locale={locale} classes={classes}
                                              camerasToShow={camerasToShow} deploymentId={deploymentId}
                                              displayedCameraListProp={displayedCameraList}
                                              filters={filters} live={live}
                                              displayVideoPlayers={displayVideoPlayers}/>}
                </Grid>
                <Grid container className={classes.alertTable}>
                    {(live || displayVideoPlayers) && <AuthorizedElement permission={PERMISSION_RECORDING_VIEW}>
                                                            <AlertsContainer deploymentId={deploymentId} mosaicId={mosaicId}
                                                                locale={locale} customerId={customerId}
                                                                handleSetLive={handleSetLive} live={live}
                                                                filters={filters}/>
                                                        </AuthorizedElement>}
                </Grid>
            </div>}
            <FullScreen handle={handleMosaic}>
                {handleMosaic.active &&
                <FullScreenMosaic arrayOfCameras={displayedCameraList} fullScreen={handleMosaic.active}
                                  live={live} poster={poster} filters={filters} mosaicId={mosaicId}/>}
            </FullScreen>
        </div>)
};

CameraMosaicComponent.propTypes = {
    locale: PropTypes.any
};

export default CameraMosaicComponent;
