import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./CustomersIconStyles";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";

const useStyles = makeStyles(theme => styles(theme));

const CustomersIconComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PeopleAltRoundedIcon className={classes.img} />
    </div>
  );
};

export default CustomersIconComponent;
