import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import AbstractTable from "../../shared/abstractTable/AbstractTable";
import {
  GRAY,
  PERMISSION_USER_MANAGE,
} from "../../../utils/constants";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/utils";
import { Typography } from "@material-ui/core";

const UsersTableComponent = ({
  userList,
  handlePasswordModal,
  classes,
  handleRemoveAccessConfirmation,
  handleDeleteUserConfirmation
}) => {
  const intl = useIntl();

  const permissions = useSelector(state => state.commonState.permissions);
  const manageableUserRoles = useSelector(state => state.commonState.manageableUserRoles);
  const [permissionToManage, setPermissionToManage] = useState(false);

  const USER_ID = intl.formatMessage({ id: "user_id" });
  const USER_NAME = intl.formatMessage({ id: "user_username" });
  const USER_DESCRIPTION = intl.formatMessage({ id: "user_description" });
  const USER_ACTIONS = intl.formatMessage({ id: "actions" });
  const USER_ROLE = intl.formatMessage({ id: "user_role" });

  useEffect(() => {
    if (!!permissions) {
      setPermissionToManage(permissions.includes(PERMISSION_USER_MANAGE));
    }
  }, [permissions]);

  const columns = [
    {
      title: USER_ID,
      field: "_id",
      headerStyle: {
        width: "15%"
      },
      cellStyle: {
        width: "15%"
      }
    },
    {
      title: USER_NAME,
      field: "credentials.username",
      headerStyle: {
        width: "10%"
      },
      cellStyle: {
        width: "10%"
      }
    },
    {
      title: "",
      field: "valid_since",
      headerStyle: {
        width: "15%"
      },
      cellStyle: {
        width: "15%"
      },
      render: rowData =>
        rowData.is_temporary ? (
          <div className={classes.validaDatesContainer}>
            <Typography variant="caption">
              {intl.formatMessage(
                { id: "user_validity_from" },
                {
                  validSince: formatDate(rowData.valid_since * 1000)
                }
              )}
            </Typography>
            <Typography variant="caption">
              {intl.formatMessage(
                { id: "user_validity_to" },
                {
                  validUntil: formatDate(rowData.valid_until * 1000)
                }
              )}
            </Typography>
          </div>
        ) : (
          ""
        )
    },
    {
      title: USER_DESCRIPTION,
      field: "description",
      headerStyle: {
        width: "20%"
      },
      cellStyle: {
        width: "20%"
      }
    },
    {
      title: USER_ROLE,
      field: "roles",
      headerStyle: {
        width: "10%"
      },
      cellStyle: {
        width: "10%"
      }
    },
    permissionToManage && {
      title: USER_ACTIONS,
      render: rowData =>
        rowData.roles.some(role => manageableUserRoles?.includes(role)) ? (
          <div className={classes.tableButtonContainer}>
            <Tooltip title={intl.formatMessage({ id: "user_delete_access" })}>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleRemoveAccessConfirmation(rowData)}
              >
                <HighlightOffRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: "user_delete_user" })}>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleDeleteUserConfirmation(rowData)}
              >
                <DeleteForeverRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: "user_edit_password" })}>
              <IconButton
                className={classes.iconButton}
                onClick={() => handlePasswordModal(rowData)}
              >
                <VpnKeyRoundedIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : null,
      headerStyle: {
        width: "10%"
      },
      cellStyle: {
        width: "10%"
      }
    }
  ].filter(Boolean);

  return (
    <AbstractTable
      columns={columns}
      data={userList || []}
      options={{
        actionsColumnIndex: -1,
        showEmptyDataSourceMessage: true,
        doubleHorizontalScroll: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        search: false,
        toolbar: false,
        headerStyle: {
          backgroundColor: GRAY,
          color: "white",
          fontWeight: "bold"
        }
      }}
      style={{ boxShadow: "4px 4px 20px rgba(38, 37, 37, 0.1)" }}
    />
  );
};

UsersTableComponent.propTypes = {
  contractList: PropTypes.array
};

export default UsersTableComponent;
