import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { convertHexToFilter } from "../../../../utils/colorUtils";
import "./spinner.css";

const useStyles = makeStyles(theme => ({
  spinner: {
    filter: convertHexToFilter(theme.palette.primary.main)
  }
}));
const Spinner = () => {
  const classes = useStyles();

  return <span className={`loader ${classes.spinner}`}></span>;
};

export default Spinner;
