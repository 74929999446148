import React, { useEffect, useRef, useState } from "react";
import { VIDEO_RATIO_PERCENTAGE } from "../../../../../../utils/constants";
import TemporaryVideoComponent from "../../../../../common/temporaryVideoComponent/TemporaryVideoComponent";

const TemporaryVideoCellComponent = ({
  mute,
  poster,
  filters,
  live,
  deployment_id,
  camera,
  detail,
  index,
  camerasList,
  mosaicId,
  fullScreen
}) => {
  const element = useRef(null);
  const [videoSize, setVideoSize] = useState(null);

  const handleResize = () => {
    const width = element.current.clientWidth;
    setVideoSize(width * VIDEO_RATIO_PERCENTAGE);
  };

  useEffect(() => {
    if (
      element.current.clientWidth !== element.current.clientHeight &&
      element.current.clientWidth > element.current.clientHeight
    ) {
      handleResize();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.current, index, camerasList]);

  return (
    <div ref={element} className={"videoCell"}>
      <TemporaryVideoComponent
        mute={mute}
        poster={poster}
        filters={filters}
        live={live}
        deployment_id={deployment_id}
        camera={camera}
        detail={detail}
        index={index}
        camerasList={camerasList}
        mosaicId={mosaicId}
        videoSize={videoSize}
        fullScreen={fullScreen}
      />
    </div>
  );
};

export default TemporaryVideoCellComponent;
