import { GRAY, MIN_HEIGHT } from "../../utils/constants";
import { tenantPrimaryButton } from "../../utils/tenantStyles";

export const styles = theme => ({
  root: {
    zIndex: 0,
    padding: theme.spacing(0, 20),
    textAlign: "left",
    minHeight: MIN_HEIGHT,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(6)
    }
  },
  cardRoot: {
    textAlign: "left",
    boxShadow: "4px 4px 20px rgba(38, 37, 37, 0.1)",
    borderRadius: 0
  },
  cardHeader: {
    backgroundColor: GRAY,
    color: "white"
  },
  cardContent: {
    color: GRAY,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(3, 4)
  },
  userContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(12)
  },
  infoContainer: {
    display: "flex",
    gap: theme.spacing(0, 20),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },
  messageContainer: {
    marginTop: theme.spacing(3)
  },
  title: {
    fontWeight: "normal",
    color: theme.palette.primary.main
  },
  primaryButton: tenantPrimaryButton(theme),
  disabled: {
    backgroundColor: theme.palette.disabled.light + "!important",
    border: 0 + "!important",
    color: theme.palette.disabled.main + "!important"
  },
  warningContainer: {
    display: "flex",
    alignItems: "center"
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(6),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(3),
      alignItems: "center"
    }
  },
  textFieldBlock: {
    display: "flex",
    gap: theme.spacing(0, 12),
    width: "fit-content",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: theme.spacing(3),
      justifyContent: "center"
    }
  },
  textField: {
    minWidth: "14rem"
  },
  formFooter: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(3)
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});
