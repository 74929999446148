import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  tenantPrimaryButton,
  tenantSecondaryButton
} from "../../../../utils/tenantStyles";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "bold"
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(3),
    width: "100%",
    paddingBottom: theme.spacing(2)
  },
  buttonCancel: tenantSecondaryButton(theme),
  buttonAccept: tenantPrimaryButton(theme),
  dialog: {
    margin: "auto"
  }
}));

const AlertDialog = ({
  buttonCloseText,
  buttonAcceptText = false,
  description,
  onClose,
  dialogOnAccept,
  openDialog,
  titleDialog,
  fullWidth = true
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openDialog || false}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      maxWidth={"lg"}
      fullWidth={fullWidth}
      className={!fullWidth && classes.dialog}
    >
      <DialogTitle className={classes.title} id="alert-dialog-slide-title">
        {titleDialog}
      </DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <div className={classes.buttonGroup}>
          {!!buttonCloseText && (
            <Button
              onClick={onClose}
              className={classes.buttonCancel}
              disableElevation
              variant="contained"
            >
              {buttonCloseText}
            </Button>
          )}
          {!!buttonAcceptText && (
            <Button
              onClick={dialogOnAccept}
              className={classes.buttonAccept}
              disableElevation
              variant="contained"
            >
              {buttonAcceptText}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  buttonCloseText: PropTypes.string,
  description: PropTypes.any,
  onClose: PropTypes.func,
  openDialog: PropTypes.bool,
  titleDialog: PropTypes.string,
  buttonAcceptText: PropTypes.string,
  dialogOnAccept: PropTypes.func
};

export default AlertDialog;
