export const TURN_OFF_LOADING_ACTION = 'TURN_OFF_LOADING_ACTION';
export const TURN_ON_LOADING_ACTION = 'TURN_ON_LOADING_ACTION';
export const FILTER_CAMERA_MOSAIC_ACTION = 'FILTER_CAMERA_MOSAIC_ACTION';
export const PUT_CAMERA_MOSAIC_ACTION = 'PUT_CAMERA_MOSAIC_ACTION';
export const SET_STYLES_ACTION = 'SET_STYLES_ACTION';
export const SET_PERMISSIONS_ACTION = 'SET_PERMISSIONS_ACTION';
export const SET_USER_CREATION_ROLES_ACTION = 'SET_USER_CREATION_ROLES_ACTION';
export const SET_SECONDS_ACTION = 'SET_SECONDS_ACTION';
export const SET_PLAYING_ACTION = 'SET_PLAYING_ACTION';
export const SET_REWIND_ACTION = 'SET_REWIND_ACTION';
export const SET_FAST_FORWARD_ACTION = 'SET_FAST_FORWARD_ACTION';
export const SET_CAMERA_REFERENCE_ACTION = 'SET_CAMERA_REFERENCE_ACTION';
export const SET_SELECTED_ALERT_ACTION = 'SET_SELECTED_ALERT_ACTION';
export const SET_ALERTS_ACTION = 'SET_ALERTS_ACTION';
export const SET_CAMERA_SELECTED_ACTION = 'SET_CAMERA_SELECTED_ACTION';
export const SET_VIEW_LINK_MODAL_SELECTED_ACTION = 'SET_VIEW_LINK_MODAL_SELECTED_ACTION';

export const STORE_CONNECTED_CAMERA_ID = 'STORE_CONNECTED_CAMERA_ID';
export const CHANGE_CAMERA_DEFAULT_SPEED_ACTION = 'CHANGE_CAMERA_DEFAULT_SPEED_ACTION';
export const CHANGE_CAMERA_SECONDARY_SPEED_ACTION = 'CHANGE_CAMERA_SECONDARY_SPEED_ACTION';
export const CHANGE_DEAD_ZONE_ACTION = 'CHANGE_DEAD_ZONE_ACTION';
export const RESET_PTZ_VALUES = 'RESET_PTZ_VALUES';
export const LOAD_PTZ_PRESETS_VALUES_ACTION = "LOAD_PTZ_PRESETS_VALUES_ACTION";
export const GAMEPAD_CONNECTED_ACTION = "GAMEPAD_CONNECTED_ACTION";
export const STORE_VIDEO_DIMENSIONS = "STORE_VIDEO_DIMENSIONS";