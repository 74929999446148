import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export default function AuthorizedElement({
  permission,
  children,
  isAuthorizedCallback = undefined
}) {
  const permissions = useSelector(state => state.commonState.permissions);
  const isAuthorized = () => {
    if (!!permissions) {
      const isRendered = permissions.includes(permission);
      if (!!isAuthorizedCallback) {
        isAuthorizedCallback(isRendered);
      }
      return isRendered;
    } else {
      if (!!isAuthorizedCallback) {
        isAuthorizedCallback(false);
      }
      return false;
    }
  };

  return isAuthorized() && children;
}

AuthorizedElement.propTypes = {
  children: PropTypes.any.isRequired
};
