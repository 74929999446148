import React from "react";
import { useIntl } from "react-intl";
import Card from "@material-ui/core/Card";
import { CardContent, CardHeader, Typography } from "@material-ui/core";
import { formatDate } from "../../../utils/utils";
import Spinner from "../../shared/loading/components/Spinner";

const UserInfoComponent = ({ userInfo, classes, loading }) => {
  const intl = useIntl();

  return (
    <Card className={classes.cardRoot}>
      <CardHeader
        title={intl.formatMessage({ id: "user_user_info" })}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className={classes.infoContainer}>
              <div>
                <Typography>
                  {intl.formatMessage({ id: "user_username" })}
                </Typography>
                <Typography className={classes.title}>
                  {userInfo?.username}
                </Typography>
              </div>
              <div>
                <Typography>
                  {intl.formatMessage({ id: "user_firstName" })}
                </Typography>
                <Typography className={classes.title}>
                  {userInfo?.firstName}
                </Typography>
              </div>
              <div>
                <Typography>
                  {intl.formatMessage({ id: "user_lastName" })}
                </Typography>
                <Typography className={classes.title}>
                  {userInfo?.lastName}
                </Typography>
              </div>
              <div>
                <Typography>
                  {intl.formatMessage({ id: "user_role" })}
                </Typography>
                <Typography className={classes.title}>
                  {userInfo?.role}
                </Typography>
              </div>
            </div>
            <div>
              {!!userInfo?.isTemporary && (
                <>
                  <Typography>
                    {intl.formatMessage({ id: "user_validity_dates" })}
                  </Typography>
                  <Typography className={classes.title}>
                    {userInfo?.validSince
                      ? formatDate(userInfo.validSince)
                      : ""}
                    -
                    {userInfo?.validUntil
                      ? formatDate(userInfo.validUntil)
                      : ""}
                  </Typography>
                </>
              )}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default UserInfoComponent;
