import React, { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";

import PageHeaderComponent from "../common/pageHeaderComponent/PageHeaderComponent";
import {
  getContractsList,
  getCustomerStyles,
  getMosaics
} from "../../services/commonServices";
import ContractTable from "./components/contractTableComponent/ContractTable";
import {
  BREADCRUMB_SERVICE_DEPLOYMENT_LIST,
  ROLES_ADMIN,
  TIIVII_API_DEPLOYMENT_LIST_BY_USER,
  TIIVII_API_DEPLOYMENT_LIST_BY_CUSTOMER,
  GRAY,
  PERMISSION_RECORDING_VIEW,
  PERMISSION_DEPLOYMENT_VIEW,
  LOCAL_STORAGE__CUSTOMER_ID,
  CSA_CUSTOMER,
  AXEGA_CUSTOMER
} from "../../utils/constants";
import AlertsContainer from "./components/alertsContainer/AlertsContainer";
import { buildUrl } from "../../utils/axiosRequests";
import { hasRoles } from "../../utils/keycloak";
import { ROUTE_CUSTOMER_LIST, ROUTE_DEPLOYMENT_LIST } from "../../utils/routes";
import MosaicFormComponent from "./components/mosaicListComponent/mosaicFormComponent/MosaicFormComponent";
import { useIntl } from "react-intl";
import MosaicsListComponent from "./components/mosaicListComponent/MosaicListComponent";
import { TENANT_STYLES, tenantPrimaryButton } from "../../utils/tenantStyles";
import {
  fetchDeploymentCameras,
  setStylesActionCreator
} from "../../actions/commonActions";
import AlertDialog from "../common/alertContainer/components/AlertDialog";
import ViewMosaicComponent from "./components/mosaicListComponent/ViewMosaicComponent";
import AuthorizedElement from "../shared/auth/AuthorizedElement";
import withAuthorization from "../shared/auth/withAuthorization";
import UELogo from "../../images/Logo_EU_ATEMPO.png";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 0,
    padding: theme.spacing(0, 20),
    marginBottom: "90px",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(0, 6)
    }
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(6)
  },
  mapTableContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },
  map: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3)
    }
  },
  tableContainer: {
    width: "100%"
  },
  contract: {
    margin: theme.spacing(1)
  },
  sectionTitle: {
    marginBottom: theme.spacing(2)
  },
  paper: {
    width: "100%"
  },
  primaryButton: tenantPrimaryButton(theme),
  flexTitle: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    color: GRAY
  },
  cameraMosaicsTable: {
    width: "100%",
    marginBottom: theme.spacing(2)
  }
}));

const DeploymentListComponent = ({ locale }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { keycloak } = useKeycloak();
  const classes = useStyles();
  const { customer_id } = useParams();
  const isAdmin = hasRoles(ROLES_ADMIN);

  const customer = localStorage.getItem("customer");

  const [deployments, setDeployments] = useState([]);
  const [deploymentList, setDeploymentList] = useState([]);
  const [mosaicList, setMosaicList] = useState();
  const [mosaic, setMosaic] = useState(null);
  const [openMosaicForm, setOpenMosaicForm] = useState(false);
  const [newMosaic, setNewMosaic] = useState(null);
  const [readMosaic, setReadMosaic] = useState(false);
  const [username, setUsername] = useState(null);

  const urlByCustomer = buildUrl(TIIVII_API_DEPLOYMENT_LIST_BY_CUSTOMER, {
    customer_id: customer_id
  });
  const urlByUser = buildUrl(TIIVII_API_DEPLOYMENT_LIST_BY_USER, {
    username: username
  });

  const getCustomerStylesCallback = (response, customer) => {
    if (Object.keys(response?.data).length === 0) {
      dispatch(setStylesActionCreator(TENANT_STYLES));
    } else {
      const hasSecondaryLogo =
        customer === CSA_CUSTOMER || customer === AXEGA_CUSTOMER;

      const styles = {
        ...response.data,
        ...(hasSecondaryLogo && { secondaryLogo: UELogo })
      };
      dispatch(setStylesActionCreator(styles));
    }
  };

  useEffect(() => {
    if (!!customer_id) {
      if (!localStorage.getItem(LOCAL_STORAGE__CUSTOMER_ID)) {
        localStorage.setItem(LOCAL_STORAGE__CUSTOMER_ID, customer_id);
      }
      getCustomerStyles(customer_id, r =>
        getCustomerStylesCallback(r, customer_id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allCameras = useSelector(state => state.commonState.cameraList);
  const [customerCameras, setCustomerCameras] = useState(null);

  const handleSetMosaicList = response => {
    setMosaicList(response.data);
  };

  useEffect(() => {
    getMosaics(handleSetMosaicList);
  }, []);

  const loadCustomerCameras = useCallback(() => {
    const cameras = [];
    for (const deploymentId of Object.keys(allCameras)) {
      cameras.push(allCameras[deploymentId]);
    }
    setCustomerCameras(cameras.flat(1));
  }, [allCameras]);

  useEffect(() => {
    if (deploymentList && allCameras) {
      loadCustomerCameras();
    }
  }, [deploymentList, allCameras, loadCustomerCameras]);

  useEffect(() => {
    if (keycloak) {
      keycloak.loadUserProfile().then(response => {
        setUsername(response.username);
      });
    }
  }, [keycloak]);

  useEffect(() => {
    if (customer_id) {
      localStorage.setItem("customer", customer_id);
    } else if (!customer_id && deployments.length) {
      localStorage.setItem("customer", deployments[0].customer_id);
    }
  }, [customer_id, deployments]);

  const successContractList = useCallback(response => {
    setDeployments(response.data);
    setDeploymentList(
      response.data.map(deployment => deployment.deployment_id)
    );
  }, []);

  useEffect(() => {
    if (customer_id && isAdmin) {
      getContractsList(urlByCustomer, successContractList);
    }
    if (customer_id && !isAdmin) {
      history.replace(ROUTE_DEPLOYMENT_LIST);
    }
    if (!customer_id && isAdmin) {
      history.replace(ROUTE_CUSTOMER_LIST);
    }
  }, [customer_id, isAdmin, history, urlByCustomer, successContractList]);

  useEffect(() => {
    if (!isAdmin && username) {
      getContractsList(urlByUser, successContractList);
    }
  }, [username, isAdmin, urlByUser, successContractList]);

  useEffect(() => {
    if (deployments) {
      for (const deployment of deployments) {
        void dispatch(fetchDeploymentCameras(deployment.deployment_id));
      }
    }
  }, [deployments, dispatch]);

  const handleOpenMosaicForm = isNewMosaic => {
    setOpenMosaicForm(true);
    handleSetNewMosaic(isNewMosaic);
  };

  const handleCloseMosaicForm = () => {
    setOpenMosaicForm(false);
    setMosaic(null);
    handleSetNewMosaic(null);
    setReadMosaic(false);
  };

  const handleSetMosaicSelected = data => {
    setMosaic(data);
  };

  const handleSetNewMosaic = value => {
    setNewMosaic(value);
  };

  const handleViewMosaic = value => {
    setMosaic(value);
    setReadMosaic(true);
  };

  return (
    <div className={classes.root}>
      <PageHeaderComponent type={BREADCRUMB_SERVICE_DEPLOYMENT_LIST} />
      <div className={classes.bodyContainer}>
        <div className={classes.mapTableContainer}>
          <Paper className={classes.paper} elevation={0}>
            <ContractTable contractList={deployments} />
          </Paper>
        </div>
        <div className={classes.cameraMosaicsTable}>
          <div className={classes.flexTitle}>
            <Typography className={classes.subtitle} variant="h5">
              {intl.formatMessage({ id: "filter_title" })}
            </Typography>
            <Button
              className={classes.primaryButton}
              onClick={() => handleOpenMosaicForm(true)}
            >
              {intl.formatMessage({ id: "filter_button_new" })}
            </Button>
          </div>
          <AlertDialog
            titleDialog={intl.formatMessage({ id: "filter_title" })}
            description={
              <MosaicFormComponent
                deploymentList={deploymentList}
                handleCloseMosaicForm={handleCloseMosaicForm}
                handleSetMosaicSelected={handleSetMosaicSelected}
                mosaic={mosaic}
                customerCameras={customerCameras}
                allCameras={allCameras}
                newMosaic={newMosaic}
                readMosaic={readMosaic}
                getMosaics={getMosaics}
                handleSetMosaicList={handleSetMosaicList}
              />
            }
            onClose={handleCloseMosaicForm}
            openDialog={openMosaicForm}
          />
          <AlertDialog
            titleDialog={intl.formatMessage({ id: "filter_title" })}
            description={
              <ViewMosaicComponent
                deploymentList={deploymentList}
                handleCloseMosaicForm={handleCloseMosaicForm}
                handleSetMosaicSelected={handleSetMosaicSelected}
                mosaic={mosaic}
                customerCameras={customerCameras}
                allCameras={allCameras}
                newMosaic={newMosaic}
                readMosaic={readMosaic}
                getMosaics={getMosaics}
                handleSetMosaicList={handleSetMosaicList}
              />
            }
            onClose={handleCloseMosaicForm}
            openDialog={readMosaic}
          />
          <MosaicsListComponent
            locale={locale}
            handleOpenMosaicForm={handleOpenMosaicForm}
            handleViewMosaic={handleViewMosaic}
            handleSetMosaicSelected={handleSetMosaicSelected}
            mosaicList={mosaicList}
            getMosaics={getMosaics}
            handleSetMosaicList={handleSetMosaicList}
          />
        </div>

        <AuthorizedElement permission={PERMISSION_RECORDING_VIEW}>
          <AlertsContainer locale={locale} customerId={customer} />
        </AuthorizedElement>
      </div>
    </div>
  );
};

DeploymentListComponent.propTypes = {
  locale: PropTypes.object
};

export default withAuthorization(PERMISSION_DEPLOYMENT_VIEW)(
  DeploymentListComponent
);
