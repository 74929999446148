import React, { useState, useEffect } from "react";
import {
  BREADCRUMB_PROFILE,
  MIN_HEIGHT,
  ROLE_STARTS_WITH
} from "../../utils/constants";
import { useKeycloak } from "@react-keycloak/web";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./ProfileStyles";
import PageHeaderComponent from "../common/pageHeaderComponent/PageHeaderComponent";
import UserInfoComponent from "./components/UserInfoComponent";
import PasswordComponent from "./components/PasswordComponent";
import { getUserInfo } from "../../services/commonServices";

const useStyles = makeStyles(theme => styles(theme, { MIN_HEIGHT }));

const ProfileComponent = () => {
  const classes = useStyles();
  const { keycloak } = useKeycloak();

  const [loading, setLoading] = useState(false);

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (keycloak.token) {
      keycloak.loadUserProfile().then(data => {
        const userData = {
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          role: keycloak?.realmAccess.roles.find(role =>
            role.startsWith(ROLE_STARTS_WITH)
          )
        };

        getUserInfo(userData?.username, r => handleSetUserInfo(r, userData));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.token]);

  const handleSetUserInfo = (response, userData) => {
    setUserInfo({
      isTemporary: response?.data?.is_temporary,
      validSince: response?.data?.valid_since,
      validUntil: response?.data?.valid_until,
      username: userData?.username,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      role: userData?.role
    });

    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <PageHeaderComponent type={BREADCRUMB_PROFILE} />
      <div className={classes.userContent}>
        <UserInfoComponent
          userInfo={userInfo}
          classes={classes}
          loading={loading}
        />
        <PasswordComponent
          classes={classes}
          username={userInfo?.username}
          partnerId={userInfo?.owned_by_partner_id}
        />
      </div>
    </div>
  );
};

export default ProfileComponent;
