import React from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import format from "date-fns/format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import {
  DATES_FIELDS,
  GRAY,
  LOCAL_STORAGE__LOCALE
} from "../../../../utils/constants";
import { es, enGB } from "date-fns/locale";
import { isObject } from "../../../../utils/utils";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  primaryHeading: {
    color: "white",
    backgroundColor: GRAY,
    border: "1px grey solid"
  },
  accordionBody: {
    overflow: "auto",
    boxShadow: "4px 4px 20px rgba(38, 37, 37, 0.1)"
  },
  list: {
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    gap: 12
  }
}));

const ShowInfoCameraComponent = ({ camera }) => {
  const intl = useIntl();
  const classes = useStyles();

  const locale = localStorage.getItem(LOCAL_STORAGE__LOCALE);

  const {
    serial,
    brand,
    description,
    current_firmware,
    model,
    public_interface,
    private_interface,
    status
  } = camera;

  const basicProperties = {
    serial,
    brand,
    description,
    current_firmware,
    model
  };

  const itemList = (property, objectInformation) => {
    let isTimestamp = DATES_FIELDS.find(field => field === property)
      ? new Date(+objectInformation[property]).getTime() > 0
      : undefined;
    return (
      <li key={property}>
        <strong>{intl.formatMessage({ id: property }) || property}</strong>:
        {isTimestamp
          ? format(+objectInformation[property] * 1000, "PPpp", {
              locale: locale === "es" ? es : enGB
            })
          : objectInformation[property] === null
          ? intl.formatMessage({ id: "unknown" })
          : JSON.stringify(objectInformation[property])}
      </li>
    );
  };

  const arrayItemList = (data, index) => {
    if (!isObject(data)) {
      return <li key={index}>{data}</li>;
    } else {
      return (
        <>
          {index === 0 && <Divider />}
          {objectIterator(data)}
          <Divider />
        </>
      );
    }
  };

  const objectIterator = objectInformation =>
    objectInformation
      ? Object.keys(objectInformation).map(property => {
          if (
            typeof objectInformation[property] === "object" &&
            objectInformation[property] !== null
          ) {
            const keys = Object.keys(objectInformation[property]);

            return (
              <div className={classes.list}>
                <strong>
                  {intl.formatMessage({ id: property }) || property}
                </strong>
                {!Array.isArray(objectInformation[property]) ? (
                  <ul className={classes.list}>
                    {keys.map(key =>
                      itemList(key, objectInformation[property])
                    )}
                  </ul>
                ) : (
                  <ul className={classes.list}>
                    {objectInformation[property].map((data, index) =>
                      arrayItemList(data, index)
                    )}
                  </ul>
                )}
              </div>
            );
          } else {
            return itemList(property, objectInformation);
          }
        })
      : null;

  const [expanded, setExpanded] = React.useState(0);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionToOPen = (key, property, properties) => (
    <Accordion
      expanded={expanded === key}
      onChange={handleChange(key)}
      key={key}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        aria-controls="panel1bh-content"
        id={key}
        className={classes.primaryHeading}
      >
        {intl.formatMessage({ id: property })}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionBody}>
        <ul className={classes.list}> {objectIterator(properties)} </ul>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      {basicProperties &&
        accordionToOPen(0, "basic_information", basicProperties)}
      {public_interface &&
        accordionToOPen(1, "public_interface", public_interface)}
      {private_interface &&
        accordionToOPen(2, "private_interface", private_interface)}
      {status && accordionToOPen(3, "status", status)}
    </>
  );
};

ShowInfoCameraComponent.propTypes = {
  camera: PropTypes.object.isRequired
};

export default ShowInfoCameraComponent;
