import React from "react";
import PropTypes from "prop-types";
import style from "./Modal.module.css";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  tenantPrimaryButton,
  tenantSecondaryButton
} from "../../../utils/tenantStyles";

const useStyles = makeStyles(theme => ({
  primaryButton: tenantPrimaryButton(theme),
  secondaryButton: tenantSecondaryButton(theme)
}));

const ModalComponent = ({
  body,
  handleClose = void 0,
  buttonAcceptText,
  buttonCancelText,
  handleSave = void 0,
  saveDisabled = false,
  displayCloseButton = true,
  slim = false
}) => {
  const classes = useStyles();
  const handleBackdropClick = event => {
    if (event.target === event.currentTarget && !!handleClose) {
      handleClose();
    }
  };

  return (
    <section className={style.over} onClick={handleBackdropClick}>
      <div className={`${style.container} ${slim && style.containerSlim}`}>
        {body}
        <div className={style.buttonContainer}>
          {displayCloseButton && !!handleClose && (
            <Button className={classes.secondaryButton} onClick={handleClose}>
              {buttonCancelText}
            </Button>
          )}
          {!!buttonAcceptText && !!handleSave && (
            <Button
              className={classes.primaryButton}
              disabled={saveDisabled}
              onClick={handleSave}
            >
              {buttonAcceptText}
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

ModalComponent.propTypes = {
  body: PropTypes.node.isRequired,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  buttonAcceptText: PropTypes.string
};

ModalComponent.defaultProps = {
  handleSave: undefined,
  buttonAcceptText: ""
};

export default ModalComponent;
