import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./LinkIconStyles";
import LinkIcon from "@material-ui/icons/Link";

const useStyles = makeStyles(theme => styles(theme));

const LinkIconComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LinkIcon className={classes.img} />
    </div>
  );
};

export default LinkIconComponent;
